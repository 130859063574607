import { useEffect } from 'react';
// import Helmet from 'react-helmet';
import { navigate } from 'gatsby';
import { isMobile } from 'react-device-detect';

export default function Customer() {
  useEffect(() => {
    const goToPersonalPage = () => {
      const lastPage = localStorage.getItem('lastPage');
      if (isMobile && !lastPage) {
        navigate('/personal');
      } else if (lastPage) {
        navigate(lastPage);
      } else {
        navigate('/business');
      }
    };
    goToPersonalPage();
  }, []);
  return null;
}
